import { useRouter } from "next/router";

import { ChevronRightIcon } from "@heroicons/react/solid";
import { BookmarkAltIcon, MailIcon, PhoneIcon } from "@heroicons/react/outline";
import Footer from "../components/frontpage/footer";
import Link from "next/link";
import Image from "next/image";
import Logo from "../public/logoSymbola_blue500.png";

const links = [
  {
    title: "Email Support",
    href: "mailto:support@symbo.la",
    description: "Contact our support team at any time for help.",
    icon: MailIcon,
  },
  {
    title: "Phone Support",
    href: "/app",
    description: "Visit the dashboard to contact phone support",
    icon: PhoneIcon,
  },
  {
    title: "Documentation",
    href: "/support",
    description: "Read our documentation & guides",
    icon: BookmarkAltIcon,
  },
];

// placeholder 404 page to catch all non existant doc pages and return a prettier 404

export default function Custom404() {
  const router = useRouter();

  const isDocs404 = router.asPath.includes("/docs/");

  if (isDocs404) {
    return (
      // we'll check for /docs and load the docs custom
      <>
        <div className="bg-white ">
          <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-xl mx-auto py-6 ">
              <div className="text-center">
                <Image height={69} width={55} src={Logo} alt="Symbola logo" />

                <p className="text-sm mt-12 font-semibold text-blue-600 uppercase tracking-wide">
                  DOCUMENTATION
                </p>
                <h1 className="my-6 text-2xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  {router.asPath
                    .substring(6)
                    .replace(/-/g, " ")
                    .replace(/%20/g, " ")}
                </h1>
                <p className="mt-2 text-lg text-gray-500">
                  We&apos;re upgrading our documentation and do not have a page
                  about{" "}
                  {router.asPath
                    .substring(6)
                    .replace(/-/g, " ")
                    .replace(/%20/g, " ")
                    .toLowerCase()}
                  . In the meantime, you can contact our support team with any
                  questions and we will be happy to assist!
                </p>
              </div>
              <div className="mt-12">
                <h2 className="text-sm font-semibold text-gray-500 tracking-wide ">
                  RESOURCES
                </h2>
                <ul
                  role="list"
                  className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200"
                >
                  {links.map((link, linkIdx) => (
                    <li
                      key={linkIdx}
                      className="relative py-6 flex items-start space-x-4"
                    >
                      <div className="flex-shrink-0">
                        <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-blue-50">
                          <link.icon
                            className="h-6 w-6 text-blue-700"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div className="min-w-0 flex-1">
                        <h3 className="text-base font-medium text-gray-900">
                          <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                            <a href={link.href} className="focus:outline-none">
                              <span
                                className="absolute inset-0"
                                aria-hidden="true"
                              />
                              {link.title}
                            </a>
                          </span>
                        </h3>
                        <p className="text-base text-gray-500">
                          {link.description}
                        </p>
                      </div>
                      <div className="flex-shrink-0 self-center">
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </li>
                  ))}
                </ul>
                <div className="mt-8">
                  <Link href="/">
                    <a className="text-base font-medium text-blue-600 hover:text-blue-500">
                      Or go back home<span aria-hidden="true"> &rarr;</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>

        <Footer />
      </>
    );
  } else {
    return (
      <>
        <div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="max-w-max mx-auto">
            <main className="sm:flex">
              <p className="text-4xl font-extrabold text-blue-600 sm:text-5xl">
                404
              </p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                    Page not found
                  </h1>
                  <p className="mt-1 text-base text-gray-500">
                    Please check the URL in the address bar and try again.
                  </p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <Link href="/">
                    <a className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      Go back home
                    </a>
                  </Link>
                  <a
                    href="mailto:support@symbo.la"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Contact support
                  </a>
                </div>
              </div>
            </main>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
